/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';

import Layout from '../components/layout/Layout';

import PageTitleSection from '../components/common/PageTitleSection';
import FullWidthImage from '../components/common/FullWidthImage';
import AboutUsHero from '../assets/images/StockPhotos/optimized-photos/partners/partner-hero.png';
import roadmapIco from '../assets/images/icons/colored/roadmap.svg';
import testDriveIco from '../assets/images/icons/colored/test-drive.svg';
import legalTechIco from '../assets/images/icons/colored/legal-tech.svg';
import LatestNews from '../components/news/LatestNews';
import IconContent from '../components/careers/IconContent';
import CenteredContent from '../components/common/CenteredContent';
import ContentRow from '../components/common/ContentRow';
import ModalDialog from '../components/common/ModalDialog';
import SteeringGroupForm from '../components/steering-group/SteeringGroupForm';
import Investors from '../components/steering-group/Investors';

import kroll from '../assets/images/logos/company_logo/kroll.png';
import tlt from '../assets/images/logos/company_logo/tlt.svg';
import addleshawGoddard from '../assets/images/logos/company_logo/addleshaw-goddard--dark.png';
import allenAndOvery from '../assets/images/logos/company_logo/allen-and-overy.png';
import ashurst from '../assets/images/logos/company_logo/ashurst.png';
import paulHastings from '../assets/images/logos/company_logo/paul-hastings.png';
import dentons from '../assets/images/logos/company_logo/dentons.png';
import pinsentMasons from '../assets/images/logos/company_logo/pinsent-masons.png';
import barclaysEagleLabs from '../assets/images/logos/company_logo/barclays-eagle-labs-02.png';
import birdandbird from '../assets/images/logos/company_logo/birdandbird.png';
import simmons from '../assets/images/logos/company_logo/simmons-and-simmons.png';
import lonelyPlanet from '../assets/images/logos/company_logo/lonely-planet.png';
import pexa from '../assets/images/logos/company_logo/pexa.png';
import background from '../assets/images/gifs/graphic-lines.png';
import useWindowSize from '../utils/windowSize';

function SteeringGroup() {
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();

  const windowSize = useWindowSize();
  const [isSmallDevice, setSmallDevice] = useState(false);
  const [showSteeringGroupForm, setShowSteeringGroupForm] = useState(false);

  useEffect(() => {
    if (windowSize.width <= 768) setSmallDevice(true);
    else setSmallDevice(false);
  }, [windowSize]);

  return (
    <Layout
      title="Movers. Shakers. Thinkers. Doers."
      description="LawAdvisor’s products have been informed by the legal industry’s brightest minds. Their innovation and desire to challenge the status-quo is imprinted in all our products’ DNA."
      image="/metaimages/meta-image-la-ventures.png"
    >
      <div className="partners-page">
        <PageTitleSection
          data-enable
          haveArrow={!isSmallDevice}
          executeScroll={executeScroll}
        >
          <h2 className="mb-16 mb-sm-24">Movers. Shakers. Thinkers. Doers.</h2>
          <button
            id="work-with-us"
            onClick={() => {
              setShowSteeringGroupForm(true);
            }}
            className="btn-primary btn btn-sm"
          >
            Join the Group
          </button>
        </PageTitleSection>
        <FullWidthImage img={AboutUsHero} />
        <div
          ref={myRef}
          className="partner-logo-section fit-width d-none d-md-block"
        >
          <small className="d-block">
            POWERING INNOVATION AND TECHNOLOGY ALONGSIDE
          </small>
          <div className="companies-logo">
            <div className="brands-row">
              <img className="logo birdandbird" src={birdandbird} alt="logo" />
              <img className="logo addleshawGoddard" src={addleshawGoddard} alt="logo" />
              <img className="logo simmons" src={simmons} alt="logo" />
              <img
                className="logo allenAndOvery"
                src={allenAndOvery}
                alt="logo"
              />
              <img className="logo ashurst" src={ashurst} alt="logo" />
              <img
                className="logo paulHastings"
                src={paulHastings}
                alt="logo"
              />
              {false && (
                <img className="logo dentons" src={dentons} alt="logo" />
              )}
            </div>
            <div>
              <img
                className="logo pinsentMasons"
                src={pinsentMasons}
                alt="logo"
              />
              <img
                className="logo barclaysEagleLabs"
                src={barclaysEagleLabs}
                alt="logo"
              />
              <img
                className="logo lonelyPlanet"
                src={lonelyPlanet}
                alt="logo"
              />
              <img className="logo pexa" src={pexa} alt="logo" />
              <img className="logo tlt" src={tlt} alt="logo" />
              <img className="logo kroll" src={kroll} alt="logo" />
            </div>
          </div>
        </div>
        <div className="fit-width">
          <div className="col-12 col-sm-9">
            <ContentRow className="pt-82 pb-0">
              <CenteredContent>
                <div className="paragraph mb-sm-32">
                  <h4 className="mb-64">
                    LawAdvisor’s products have been informed by the legal
                    industry’s brightest minds. Their innovation and desire to
                    challenge the status-quo is imprinted in all our products’
                    DNA. We’re looking for more movers and shakers who can help
                    us develop best practices when it comes to efficient and
                    transformative legal services delivery. LawAdvisor seeks to
                    continuously improve our design and development process
                    based on real-life use and feedback from our Steering Group.
                  </h4>
                </div>
                <div className="paragraph mb-32">
                  <h4 className="mb-0">
                    By being involved in our
                    {' '}
                    <h4 className="text-primary">Steering Groups</h4>
                    , you will:
                  </h4>
                </div>
              </CenteredContent>
              <CenteredContent className="mb-32">
                <IconContent
                  icon={legalTechIco}
                  title="Be involved in developing game-changing transformative legal-technology."
                />
                <IconContent
                  icon={roadmapIco}
                  title="Shape and influence our development roadmap."
                />
                <IconContent
                  icon={testDriveIco}
                  className="mb-sm-48"
                  title="Nominate priority features and be the first to test-drive these in our testing environment."
                />
              </CenteredContent>
              <CenteredContent>
                <div className="paragraph mb-40 mb-sm-24">
                  <h4>
                    Gain the competitive edge and be the first to benefit from
                    world-class legal technology. Check it out for yourself, in
                    the past 12 months, we have already gone on to develop -
                    alongside our steering group - the following products
                  </h4>
                </div>
              </CenteredContent>

              <CenteredContent>
                <div className="paragraph">
                  <ul className="mb-124 mb-sm-80">
                    <li className="mb-32">
                      <h4 className="text-primary">
                        <a
                          href="https://www.fibonacci.legal"
                          rel="noreferrer"
                          target="_blank"
                        >
                          Fibonacci
                        </a>
                      </h4>
                    </li>
                    <li className="mb-32">
                      <h4 className="text-primary">
                        <a
                          href="https://www.legaleye.com"
                          rel="noreferrer"
                          target="_blank"
                        >
                          LegalEye
                        </a>
                      </h4>
                    </li>
                    <li className="mb-0">
                      <h4 className="text-primary">
                        <a
                          href="https://www.rapidcontracts.lawadvisor.com"
                          rel="noreferrer"
                          target="_blank"
                        >
                          RapidContracts
                        </a>
                      </h4>
                    </li>
                  </ul>
                </div>
              </CenteredContent>
            </ContentRow>
          </div>
        </div>
        <Investors />
        <div
          className="partner-widget d-block bg-white"
          style={{
            backgroundImage: `url(${background})`,
            backgroundPosition: 'center',
          }}
        >
          <div className="fit-width pt-100 pb-100 pt-sm-80 pb-sm-80">
            <h2 className="text-center">We look forward to working with you</h2>
            <h2 className="text-center mb-24">
              to create the future of legal practice.
            </h2>
            <div className="d-flex justify-content-center w-100">
              <button
                type="submit"
                onClick={() => {
                  setShowSteeringGroupForm(true);
                }}
                className="btn-primary subscribe-btn mb-0 text-center"
              >
                Join the Group
              </button>
            </div>
          </div>
        </div>
        <LatestNews />
      </div>
      {showSteeringGroupForm && (
        <ModalDialog closeAction={() => setShowSteeringGroupForm(false)} scroll>
          <SteeringGroupForm />
        </ModalDialog>
      )}
    </Layout>
  );
}

export default SteeringGroup;
