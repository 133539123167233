import React from 'react';

function IconContent({ className, icon, title, text }) {
  return (
    <div className={`${className} row icon-content no-gutters`}>
      <div className="col-2 col-sm-1"><img className="icon mt-0 mt-sm-2" src={icon} height="25px" /></div>
      <div className="col-9 col-sm-11 pl-0">
        <h4 className={`text-primary ${text ? '' : 'mb-32'}`}>{title}</h4>
        {text && <p>{text}</p>}
      </div>
    </div>
  );
}

export default IconContent;